import { useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  DataSetDetailView as DataSetDetailViewDS,
  LockedContent,
  DataSetAllowedActions,
  PricingTriggersKeys,
} from '@trustwise/design-system';
import { DataEntryItemModel, DataRoomDetailModel, DataSetItemModel, DataSetModel } from 'dataRooms/models';
import { PageContent } from 'core/page';
import {
  DocumentEntryCreateData,
  AddNewEntry,
  CreateDataSetFormHelpers,
  CreateDataSetValues,
  CustomSchemaActions,
  CustomSchemaEntryFormData,
  CustomSchemaValues,
  EditDataSetPayload,
} from 'dataRooms/types';
import { SetDisabled } from 'types';
import BackButton from 'core/page/parts/backButton';
import { useRefetchOnTxProcessed } from 'core/hooks';
import { getButtonInteraction } from 'core/utils';
import { getTriggersStatus, getTriggerStatusMessage } from 'pricing/utils';
import { SimpleDocumentModel } from 'media/models';
import {
  deleteDataEntry,
  deleteDataSet,
  editDataSet,
  getDataEntries,
  getDataRoomQueryOptions,
  getDataSet,
  getDataSetDetailUrl,
  getExistingDocuments,
  onCustomEntryAdd,
  onEntryFileDownload,
  onRecordReadAccess,
  onReportDownload,
  sealDataSet,
  uploadDocument,
} from './actions';

export const DataSetDetailView = () => {
  const queryClient = useQueryClient();
  const { dataRoomId, dataSetId } = useParams();
  const setRefetchQueryKeys = useRefetchOnTxProcessed();
  const navigate = useNavigate();

  if (!dataRoomId || !dataSetId) {
    throw new Error('dataRoomId and dataSetId are required');
  }

  const dataRoomQueryOptions = useMemo(() => getDataRoomQueryOptions(dataRoomId), [dataRoomId]);
  const dataRoom: DataRoomDetailModel | undefined = queryClient.getQueryData(dataRoomQueryOptions.queryKey);
  if (!dataRoom) {
    queryClient.fetchQuery(dataRoomQueryOptions);
  }

  const { data: dataSet } = useQuery({
    queryKey: ['dataSet', dataRoomId, dataSetId],
    queryFn: getDataSet,
  });

  const shouldConfirmAccess: boolean | undefined = useMemo(() => {
    if (!dataRoom) {
      return undefined;
    }
    return dataSet?.getShouldConfirmAccess(dataRoom.accessRole);
  }, [dataSet, dataRoom]);

  const { mutateAsync: deleteSet } = useMutation({
    mutationFn: () => deleteDataSet(dataRoomId, dataSetId),
    onSuccess: () => {
      queryClient.setQueryData(
        ['dataSets', dataRoomId],
        (prevState?: DataSetItemModel[]) => prevState?.filter((data) => data.id !== Number(dataSetId)),
      );
      navigate(`/${dataRoomId}`);
    },
  });

  const { mutateAsync: editSet } = useMutation({
    mutationFn: ({ values, actions }: EditDataSetPayload) => editDataSet({ dataRoomId, dataSetId, values, actions }),
    onSuccess: (data: DataSetModel) => {
      queryClient.setQueryData(['dataSet', dataRoomId, dataSetId], data);
    },
  });

  const { data: dataEntries } = useQuery({
    queryKey: ['dataEntries', dataRoomId, dataSetId],
    queryFn: getDataEntries,
    enabled: shouldConfirmAccess === false,
  });

  const { mutateAsync: addNewEntry } = useMutation({
    mutationFn: ({ data, isCustom }: AddNewEntry) => (
      isCustom && dataRoom?.schema
        ? onCustomEntryAdd({ dataRoomId, dataSetId, schema: dataRoom.schema, ...data as CustomSchemaEntryFormData })
        : uploadDocument({ dataRoomId, dataSetId, data: data as DocumentEntryCreateData })
    ),
    onSuccess: (newEntry) => {
      queryClient.setQueryData(
        ['dataEntries', dataRoomId, dataSetId],
        (prevState?: DataEntryItemModel[]) => (prevState ? [newEntry, ...prevState] : [newEntry]),
      );
    },
  });

  const { mutateAsync: deleteEntry } = useMutation({
    mutationFn: deleteDataEntry,
    onSuccess: (entryId) => {
      queryClient.setQueryData(
        ['dataEntries', dataRoomId, dataSetId],
        (prevState?: DataEntryItemModel[]) => prevState?.filter((entry) => entry.id !== entryId),
      );
    },
  });

  const { data: addEntryStatusMessage } = useQuery({
    queryKey: ['dataRoom', dataRoomId, 'triggerStatus', 'dataSetCreation'],
    queryFn: () => getTriggersStatus({
      triggersKeys: [PricingTriggersKeys.DATA_SET_ENTRY_ADD],
      ...{ dataSetId },
    }).then((status) => getTriggerStatusMessage(status)),
    enabled: dataSet?.allowedActions?.includes(DataSetAllowedActions.ADD_ENTRIES),
  });

  useEffect(() => {
    shouldConfirmAccess && onRecordReadAccess({ dataRoomId, dataSetId, shouldRedirect: false });
  }, [dataRoomId, dataSetId, shouldConfirmAccess]);


  const onDataEntryDelete = (dataEntryId: number, setDisabled: SetDisabled) => (
    deleteEntry({ dataRoomId, dataSetId, dataEntryId })
      .then(() => setDisabled(false))
  );

  const dataEntryActions = {
    onDocumentsFetch: getExistingDocuments,
    onDocumentsSearch: getExistingDocuments,
    onEntryDelete: dataSet?.isSealed || dataRoom?.isClosed ? undefined : onDataEntryDelete,
    onExistingDocumentUpload: (docId: number) => addNewEntry({ data: docId }),
    onCustomEntryUpload: (values, actions) => addNewEntry({
      data: { values: values as CustomSchemaValues, actions: actions as CustomSchemaActions },
      isCustom: true,
    }),
    onUploadNewDocument: (values, actions) => (
      addNewEntry({ data: { values, actions } })
    ),
    onFileDownload: (dataEntryId: number, fileId: number) => (
      onEntryFileDownload(dataEntryId, fileId, getDataSetDetailUrl(dataRoomId, dataSetId), dataEntries)
    ),
  };

  const onLockedActionClick = (_ev: React.MouseEvent, setDisabled?: SetDisabled) => (
    onRecordReadAccess({ dataRoomId, dataSetId, setDisabled, shouldRedirect: false })
  );

  return (
    <PageContent
      fullscreen
      pageHeading={dataSet?.name || ''}
      headerLeft={<BackButton href={`/${dataRoomId}/`} useRouterLink />}
    >
      {shouldConfirmAccess ? (
        <LockedContent
          title={gettext('Access is locked')}
          actionLabel={gettext('Confirm read access')}
          message={gettext('You need to confirm read access to view the details of this data set.')}
          interaction={getButtonInteraction(onLockedActionClick, false)}
        />
      ) : (
        <DataSetDetailViewDS
          verdict={dataSet?.verdict}
          name={dataSet?.name}
          isSealed={dataSet?.isSealed}
          allowedActions={dataSet?.allowedActions}
          dataEntries={dataEntries}
          schema={dataRoom?.schema}
          isDocumentsType={dataRoom?.isRoomTypeDocuments}
          entryActions={dataEntryActions}
          addEntryInfoMessage={addEntryStatusMessage}
          onSeal={(_ev, setDisabled) => (
            sealDataSet(dataRoomId, dataSetId, setDisabled)
              .then(() => setRefetchQueryKeys(['dataSet', dataRoomId, dataSetId]))
          )}
          onDataSetDelete={() => deleteSet()}
          onRename={(values, actions) => editSet({
            values: values as CreateDataSetValues,
            actions: actions as CreateDataSetFormHelpers,
          })}
          onReportDownload={(fileId) => (
            onReportDownload({ fileId, dataRoomId, dataSetId, reports: dataSet?._verifierReports as SimpleDocumentModel[] })
          )}
        />
      )}
    </PageContent>
  );
};
