/**
 * List of reported beneficial owners with filtering by date.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';
import DateFilterForm from 'core/forms/dateFilterForm';
import { dateToday } from 'utils/date';

const BeneficialOwnersListByDate = ({ companyId, ownershipCompanyId }) => {
  /**
   * Update state on form submit.
   */
  const [date, setDate] = useState(dateToday());
  const onSubmit = ({ dateFilterDate }, { setSubmitting }) => {
    setDate(dateFilterDate);
    setSubmitting(false);
  };

  /**
   * Fetch and set initial values when component is loaded.
   *
   * TODO handle error response
   * TODO consider useReducer instead multiple useState
   */
  const [isLoading, setIsLoading] = useState(true);
  const [beneficialOwners, setBeneficialOwners] = useState([]);
  const fetchUrl = `/api/v1/${
    ownershipCompanyId ?
      `${companyId ? `company/${companyId}/` : ''}equity/ownership/${ownershipCompanyId}/beneficial-owners/`
      : `company/${companyId}/ownership/beneficial-owners/`
  }`;
  useEffect(() => {
    axios.get(`${fetchUrl}?date=${date}`)
      .then(({ data }) => { setBeneficialOwners(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [date, fetchUrl]);

  return (
    <>
      <DateFilterForm onSubmit={onSubmit} />
      <ul className="padded-top">
        {beneficialOwners.map((owner, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className="card-base">
            <span className="title">{owner.name}</span>
            <div className="text-smaller">
              {
              `${owner.address}, `
              + `${owner.additional_address ? `${owner.additional_address}, ` : ' '}`
              + `${owner.postal_code ? `${owner.postal_code}, ` : ' '}`
              + `${owner.city}, ${owner.country_code}`
              }
              <br />
              {gettext(`- ${owner.control_label}`)}
              <br />
              {gettext(`- reported by ${owner.reported_by}`)}
              <br />
            </div>
          </li>
        ))}
      </ul>
      {!beneficialOwners.length && (
        <p className="text-center">
          {isLoading ? gettext('Loading beneficial owners...') : gettext('No beneficial owners reported.')}
        </p>
      )}
    </>
  );
};


BeneficialOwnersListByDate.defaultProps = {
  companyId: null,
  ownershipCompanyId: null,
};

BeneficialOwnersListByDate.propTypes = {
  companyId: PropTypes.string,
  ownershipCompanyId: PropTypes.string,
};

export default BeneficialOwnersListByDate;
