import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InvestmentsWidget as Widget } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl, getUrl } from 'utils/urls';
import processChartData from '../core/charts/utils';

const EquityInvestmentsWidget = ({ baseCurrency }) => {
  const [monetaryValue, setMonetaryValue] = useState(undefined);
  const [totalChartValue, setTotalChartValue] = useState(undefined);
  const [chartData, setChartData] = useState({
    values: undefined,
    labels: undefined,
    descriptions: undefined,
  });

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/total-assets-value/'))
      .then(({ data: assetsValue }) => {
        setMonetaryValue({
          number: assetsValue,
          currency: baseCurrency,
        });
      })
      .catch(console.error);
  }, [baseCurrency]);

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/portfolio-chart-data/'))
      .then(({ data }) => {
        setChartData(processChartData(data));
        setTotalChartValue(data.values.length);
      })
      .catch(console.error);
  }, [baseCurrency]);

  return (
    <Widget
      monetaryValue={monetaryValue}
      onManage={() => { window.location = getUrl('/equity/'); }}
      companiesCount={totalChartValue}
      title={gettext('Equity Investments')}
      {...chartData}
    />
  );
};

EquityInvestmentsWidget.propTypes = {
  baseCurrency: PropTypes.string.isRequired,
};


export default EquityInvestmentsWidget;
