import { BaseLegalEntityWithID } from 'contacts/interfaces/types';
import { DocumentModelDataProps } from 'media/interfaces/modelDataTypes';
import { Model } from 'core/model';
import { SimpleDocumentModel } from 'media/models';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { getGlobalContext } from 'core/globals';

interface BaseDataEntryItemModelProps {
  /**
   * Data entry ID
   */
  id: number;
}

interface DataEntryItemModelProps extends BaseDataEntryItemModelProps {
  /**
   * Legal entity (person) responsible for recording the data entry
   */
  recordedBy: BaseLegalEntityWithID;
  /**
   * Legal entity (company) responsible for recording the data entry
   */
  recordedByCompany?: BaseLegalEntityWithID;
  /**
   * Date time in which data entry was created
   */
  createdAt: string;
  /**
   * Data entry data
   */
  data: (string | number | boolean | DocumentModelDataProps)[];
}

interface IDataEntryItemModelProps extends BaseDataEntryItemModelProps {
  /**
   * Legal entity (person) responsible for recording the data entry
   */
  recordedBy: SimpleLegalEntityModel;
  /**
   * Legal entity (company) responsible for recording the data entry
   */
  recordedByCompany?: SimpleLegalEntityModel;
  /**
   * Date time in which data entry was created
   */
  creationDate: Date;
  /**
   * Data entry data
   */
  data: (string | number | boolean | SimpleDocumentModel)[];
}

const { activeEntity: { id: activeEntityId } } = getGlobalContext();

export class DataEntryItemModel extends Model implements IDataEntryItemModelProps {
  id: number;
  recordedBy: SimpleLegalEntityModel;
  recordedByCompany?: SimpleLegalEntityModel;
  creationDate: Date;
  _data: (string | number | boolean | DocumentModelDataProps)[];

  constructor({
    id, recordedBy, recordedByCompany, createdAt, data, ...restProps
  }: DataEntryItemModelProps) {
    super(restProps);
    this.id = id;
    this.recordedBy = SimpleLegalEntityModel.fromResponse(recordedBy);
    this.recordedByCompany = recordedByCompany ? SimpleLegalEntityModel.fromResponse(recordedByCompany) : undefined;
    this.creationDate = new Date(createdAt);
    this._data = data;
  }

  get data() {
    return this._data.map((item) => {
      if (typeof item === 'object') {
        return SimpleDocumentModel.fromResponse(item);
      }
      return item;
    });
  }

  get isAddedByCurrentEntity() {
    if (this.recordedByCompany) {
      return this.recordedByCompany.id === activeEntityId;
    }
    return this.recordedBy.id === activeEntityId;
  }
}
