import { CheckboxField } from 'core/forms/fields';
import { isAndroidWebview, isIosWebview } from 'utils/general';

export const UserWarranties = () => {
  let storage = '';
  let account = '';

  if (isAndroidWebview) {
    storage = 'Google Drive';
    account = 'Google Account';
  } else if (isIosWebview) {
    storage = 'iCloud';
    account = 'Apple ID';
  } else {
    account = 'Google Account or Apple ID';
    storage = 'Google Drive or iCloud';
  }

  return (
    <>
      <div className="scrollable-content-area margin-bottom">
        <h1>{gettext('Warranties by User')}</h1>
        <p>
          {gettext(
            `Account recovery relies on the presence of the recovery key stored within ${storage}.`
              + ' USER confirms that if they lose or reset their current mobile device'
              + ` and at the same time lose access to their current ${account},`
              + ' they won\'t be able to regain control over their account.',
          )}
        </p>
      </div>
      <CheckboxField
        label={gettext('I confirm')}
        name="userWarrantiesConfirmation"
      />
    </>
  );
};
