import { getGlobalContext } from 'core/globals';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Asset Transaction Model
 */
export class AssetTransactionModel {
  constructor({
    id, dateTime, amount, sender,
  }) {
    this.id = id;
    this.datetime = new Date(dateTime);
    this.units = activeEntityId === sender.id ? -1 * amount : amount;
  }
}
