import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import SubscriptionCertificatesList from './list';
import SubscriptionCertificatesDetail from './detail';


const EquitySubscriptionsNavigation = () => (
  <FullPageView>
    <Router basename={getUrl('/equity/subscriptions')}>
      <Routes>
        <Route path="/">
          <Route index element={<SubscriptionCertificatesList />} />
          <Route path=":subscriptionCertificateId/*" element={<SubscriptionCertificatesDetail />} />
        </Route>
      </Routes>
    </Router>
  </FullPageView>
);


export default EquitySubscriptionsNavigation;
