import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { ModalWithContent as Modal } from '@trustwise/design-system';
import { SubmitButton, TextField } from 'core/forms/fields';
import { fieldRequired, parseErrorResponse } from 'core/forms/utils';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';

const EditPortfolioName = ({ isOpen, portfolioId, currentName, onClose }) => {
  const onSubmit = ({ name }, actions) => {
    actions.setSubmitting(true);
    axios.patch(getApiUrl(`/equity/${portfolioId}/`), { name })
      .then(
        onClose(),
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Modal
      id={`portfolio-${portfolioId}`}
      onClose={onClose}
      isOpen={isOpen}
    >
      <Formik
        initialValues={{ name: currentName }}
        validationSchema={Yup.object({ name: Yup.string().required(fieldRequired) })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <TextField label={gettext('New name')} id={`portfolio-${portfolioId}`} name="name" className="full-width" />
            <SubmitButton disabled={isSubmitting}>
              {gettext('Save')}
            </SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

EditPortfolioName.defaultProps = {
  currentName: '',
  isOpen: false,
};

EditPortfolioName.propTypes = {
  isOpen: PropTypes.bool,
  portfolioId: PropTypes.number.isRequired,
  currentName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default EditPortfolioName;
