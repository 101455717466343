import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import PaymentTokenTransfer from 'investments/paymentTokens/views/transfer';
import { FullPageView } from 'core/page';
import { PaymentTokenAssetsListView, PaymentTokenTransactionsListView } from './paymentTokens/views';
import {
  ArtworksAssetsListView,
  ArtworkAssetDetailView,
  InitializeArtworksAssetsTransferView,
  PropertyTokenCreateOrderView,
} from './artworks/views';
import UtilityTokensAssetDetailView from './utilityTokens/views/detail';
import UtilityTokensAssetsBurnView from './utilityTokens/views/burn';
import UtilityTokensAssetsListView from './utilityTokens/views/list';
import UtilityTokensAssetsTransferView from './utilityTokens/views/transfer';

const AssetsView = () => (
  <FullPageView>
    <Router basename={getUrl('/assets/')}>
      <Routes>
        <Route path="artworks/">
          <Route index element={<ArtworksAssetsListView />} />
          <Route path=":portfolioId/:tokenId/" element={<ArtworkAssetDetailView />} />
          <Route path=":portfolioId/:tokenId/transfer/" element={<InitializeArtworksAssetsTransferView />} />
          <Route path=":portfolioId/:tokenId/sell/" element={<PropertyTokenCreateOrderView />} />
        </Route>
        <Route path="utility/">
          <Route index element={<UtilityTokensAssetsListView />} />
          <Route path=":portfolioId/:tokenId/" element={<UtilityTokensAssetDetailView />} />
          <Route path=":portfolioId/:tokenId/transfer/" element={<UtilityTokensAssetsTransferView />} />
          <Route path=":portfolioId/:tokenId/burn/" element={<UtilityTokensAssetsBurnView />} />
        </Route>
        <Route path="cash/">
          <Route index element={<PaymentTokenAssetsListView />} />
          <Route path="transfers/" element={<PaymentTokenTransactionsListView />} />
          <Route path=":portfolioId/:tokenId/transfer/" element={<PaymentTokenTransfer />} />
        </Route>
        <Route path="*" element={<PageNotFoundContent />} />
      </Routes>
    </Router>
  </FullPageView>
);

export default AssetsView;
