import PropTypes from 'prop-types';
import { companyIcon, accountIcon } from 'core/svgs';
import { formatDateTime } from 'utils/date';
import { getUrl } from 'utils/urls';
import { formatNumber } from 'core/utils';

/**
  * Token card details
  *
*/
const TokenDetails = ({ token, baseCurrency }) => {
  const detailViewPath = token.contract_type === 'SB'
    ? `/equity/subscriptions/${token.certificate_id}/`
    : `/equity/${token.portfolio.id}/${token.id}/`;

  return (
    <div key={token.id} className="multi-row-card card-base">
      {token.valuation && token.valuation.has_new && <span className="new-valuation available">&bull;</span>}
      <span className="justify-content-space-between align-flex-items-center">
        <span className="title">
          {token.legal_entity.name}
        </span>
      </span>

      <a className="subtitle element-overlay" href={getUrl(detailViewPath)}>
        {token.name}
      </a>
      <div className="font-bold medium-grey text-tiny">
        {`${token.contract_type_display} ${token.token_sub_type_display ? `(${token.token_sub_type_display})` : ''}`}
      </div>
      <dl>
        <dt>{gettext('Number of shares')}</dt>
        <dd>{token.amount.toLocaleString('de-ch')}</dd>

        {token.contract_type === 'SB' && (
        <>
          <dt>{gettext('Closing date')}</dt>
          <dd>
            {formatDateTime(new Date(token.closing_date))}
          </dd>
        </>
        )}

        {token.valuation && (
        <>
          <dt className="source-type assets">
            {gettext('Price:')}
            {token.valuation.by_issuer ? companyIcon : accountIcon}
            {token.valuation.label}
          </dt>
          <dd>{`${token.valuation.currency} ${formatNumber(token.valuation.value)}`}</dd>
          <dt>{gettext('Value')}</dt>
          <dd>{`${token.valuation.currency} ${formatNumber(token.value, 0)}`}</dd>
          {baseCurrency !== token.valuation.currency && token.value_base && (
            <>
              <dt>{gettext('Value in base currency')}</dt>
              <dd>{`${baseCurrency} ${formatNumber(token.value_base, 0)}`}</dd>
            </>
          )}
        </>
        )}
      </dl>

    </div>
  );
};

TokenDetails.defaultProps = {
  baseCurrency: 'CHF',
};

TokenDetails.propTypes = {
  token: PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
    contract_type: PropTypes.string,
    contract_type_display: PropTypes.string,
    certificate_id: PropTypes.number,
    legal_entity: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    name: PropTypes.string,
    portfolio: PropTypes.shape({
      active_valuations: PropTypes.shape({}),
      address: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    token_sub_type_display: PropTypes.string,
    valuation: PropTypes.shape({
      by_issuer: PropTypes.bool,
      currency: PropTypes.string,
      has_new: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.number,
    }),
    value: PropTypes.number,
    value_base: PropTypes.number,
    closing_date: PropTypes.string,
  }).isRequired,
  baseCurrency: PropTypes.string,
};

export default TokenDetails;
